import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { YellowButton } from './commonButtons'

const Button = styled(YellowButton)`
  box-shadow: 10px 20px 40px #7e71284e;
  border-radius: 13px;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: bold;

  ${props => props.theme.media.lessThan('tablet')`
    font-size: 16px;
    padding: 10px 12px;
  `}
`

const ChargebeeLink = styled.a`
  display: block;
`

const BookOct2020WebinarButton = ({ label, type }) => {
  return (
    <ChargebeeLink
      className='btn btn-primary'
      // href='javascript:void(0)'
      href='#'
      target='_self'
      data-cb-type='checkout'
      data-cb-plan-id='onlinepunks-base'
      data-cb-addons_id_0='wecreate-webinar'
    >
      <Button type={type}>{label}</Button>
    </ChargebeeLink>
  )
}

BookOct2020WebinarButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
}

BookOct2020WebinarButton.defaultProps = {
  label: '',
  type: 'button',
}

export default BookOct2020WebinarButton
