import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '@hooks/useTranslation'
import styled from 'styled-components'
import BookOct2020WebinarButton from '@common/buttons/BookOct2020WebinarButton'
import { YellowButton } from '@common/buttons/commonButtons'
import availableLangs from '@constants/availableLangs'

import { webinarPagesPaths } from '@constants/appSettings'

const Wrapper = styled.div`
  position: fixed;
  bottom: 3%;
  right: ${props => (props.isEnglish ? 170 : 190)}px;
  z-index: 2000;
  & button {
    background: ${props => props.theme.gradient.contactButton};
    box-shadow: 15px 15px 20px #62626231;
    color: ${props => props.theme.color.semiWhite};
    border-radius: 29px;
    font-size: 13px;
    padding: 10px;
  }
  ${props => props.theme.media.lessThan('tablet')`
    right: 2%;
    bottom: 8%;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    & button {
      font-size: 11px;
    }
  `}
`

const Link = styled.a`
  display: block;
`

const BookWebinarForMainPage = () => {
  const { labelStrings, currentLang } = useTranslation()

  const EXPIRE_TIME__MS = 1603486800000 // 24th October 2020

  const showRender = Date.now() < EXPIRE_TIME__MS

  const isEnglish = currentLang === availableLangs.en

  return (
    showRender && (
      <Wrapper isEnglish={isEnglish}>
        <Link
          href={`${process.env.WECREATE_HOST}/${webinarPagesPaths.webinarOct2020}`}
          target='_blank'
          rel='noreferrer'
        >
          <YellowButton>{labelStrings.webinarOct2020.join.stickyBtn}</YellowButton>
        </Link>
      </Wrapper>
    )
  )
}

BookWebinarForMainPage.propTypes = {}

BookWebinarForMainPage.defaultProps = {}

export default BookWebinarForMainPage
