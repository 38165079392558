import React, { memo } from 'react'
import WeCreateLayout from '@layouts/WeCreateLayout'

import CookieConsent from '@common/CookieConsent'
import PageScrollHorizontalProgressBar from '@common/PageScrollHorizontalProgressBar'
import {
  HeroContainer,
  ArtistsContainer,
  InfludataContainer,
  CommunityMarketingContainer,
  EducationContainer,
  CampaignsContainer,
  NewsContainer,
} from '@containers/weCreateBrand'
// import ContactUsContainer from '@containers/ContactUsContainer'
import BookWebinarForMainPage from '@containers/webinars/BookWebinarForMainPage'

/**
 * NOTE: this page uses typeform forms. The script for typeform is added in gatsby-ssr.js
 * each container is a totally independent function, which contains all needed data for its work.
 * If container uses typeform link, you can find it in container code to change it, if needed.
 * All other assets for containers work are also in it
 */

const WeCreateMainPageContainer = () => {
  return (
    <>
      <WeCreateLayout>
        <PageScrollHorizontalProgressBar isDarkBg />
        {/* HEADER HERO SECTION */}
        <HeroContainer />
        {/* WECREATE ARTISTS */}
        {/* add new artists inside ArtistsContainer, typeforms links also inside */}
        <ArtistsContainer />
        {/* WECREATE INFLUDATA */}
        {/* */}
        <InfludataContainer />
        {/* WECREATE COMMUNITY MARKETING */}
        {/* typeform link is inslide */}
        <CommunityMarketingContainer />
        {/*  WECREATE EDUCATION */}
        <EducationContainer />
        {/* WECREATE LOVE (CAMPAIGNS) */}
        <CampaignsContainer />
        {/* WECREATE NEWS (ARTICLES) */}
        <NewsContainer />
      </WeCreateLayout>

      {/* USE COOKIES */}
      <CookieConsent />

      {/* CONTACT US (button+modal) */}
      {/* <ContactUsContainer /> */}
      <BookWebinarForMainPage />
    </>
  )
}

export default memo(WeCreateMainPageContainer)
